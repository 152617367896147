<template>
  <ul class="c-sidebar-nav-dropdown-items">
    <li
      v-for="(val, key) in navigation"
      :key="key"
      class="c-sidebar-nav-item">
      <router-link class="c-sidebar-nav-link" :to="val.url">
        <i :class="val.icon" class="mr-3"></i>{{ val.name }}
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'SidebarChildItemComponent',
  props: {
    navigation: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.c-sidebar-nav-dropdown-items{
  max-height: 100%;
}
</style>
