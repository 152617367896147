export default {
  items: [{
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fas fa-tachometer-alt'
  },
  {
    name: 'Transaction',
    url: '/transaction',
    icon: 'fas fa-shopping-cart'
  },
  {
    name: 'Logout',
    url: '/logout',
    icon: 'fa fa-sign-out-alt'
  }
  ]
}
