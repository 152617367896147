<template>
  <div
    :style="{ backgroundImage: `url(${image})`}"
    class="rounded-avatar"></div>
</template>
<script>
const img = require('@/assets/images/probanlogo.jpeg')
export default {
  name: 'UserAvatarComponent',
  props: {
    image: {
      type: String,
      default: img
    }
  }
}
</script>
<style lang="scss" scoped></style>
