export default {
  items: [{
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fas fa-tachometer-alt'
  },
  {
    name: 'Transaction',
    url: '/transaction',
    icon: 'fas fa-shopping-cart'
  },
  {
    name: 'Refund',
    url: '/refund',
    icon: 'fas fa-percent'
  },
  {
    name: 'Catalogue',
    url: '#',
    icon: '',
    submenu: [{
      name: 'Products',
      url: '/products',
      icon: 'fas fa-qrcode'
    },
    {
      name: 'Manufacture',
      url: '/products/manufacture',
      icon: 'fas fa-industry'
    },
    {
      name: 'Vehicles',
      url: '/products/vehicles',
      icon: 'fas fa-bicycle'
    }
    ]
  },
  {
    name: 'Promos',
    url: '/promos',
    icon: 'fas fa-tag'
  },
  {
    name: 'Store',
    url: '#',
    icon: '',
    submenu: [{
      name: 'Store',
      url: '/store',
      icon: 'fas fa-map-marker-alt'
    },
    {
      name: 'Region',
      url: '/store/region',
      icon: 'fas fa-map'
    }
    ]
  },
  {
    name: 'Inbox & Notifications',
    url: '/inbox',
    icon: 'fas fa-paper-plane'
  },
  {
    name: 'User',
    url: '#',
    icon: '',
    submenu: [{
      name: 'Members',
      url: '/members',
      icon: 'fas fa-user'
    }]
  },
  {
    name: 'Vouchers',
    url: '/voucher',
    icon: 'fas fa-money-bill'
  },
  {
    name: 'Logout',
    url: '/logout',
    icon: 'fa fa-sign-out-alt'
  }
  ]
}
