<template>
  <header class="c-header c-header-light c-header-fixed c-header-with-subheader ">
    
    <div class="col-10 d-flex align-items-center font-weight-bold">
      Proban Online Order Platform
    </div>
    <button @click="trigger" class="col-2 navbar-toggler sidebar-toggler d-lg-none" type="button" data-toggle="sidebar-lg-show">☰
  </button>
  </header>
</template>
<script>
export default {
  name: 'HeaderComponent',
  methods: {
  	trigger () {
    	this.$emit('action')
      console.log("trigger")
    }
  }
}
</script>
<style lang="scss" scoped></style>
