<template>
  <div class="c-app">
    <SidebarComponent :sendCommand="sendCommand"/>
    <div class="c-wrapper c-fixed-components">
      <HeaderComponent @action="closeOpen"/>
      <div class="c-body">
        <main class="c-main">
          <div class="container-fluid">
            <div class="fade-in">
              <router-view></router-view>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarComponent from '@/components/layouts/SidebarComponent'
import HeaderComponent from '@/components/layouts/HeaderComponent'

export default {
  name: 'DashboardLayout',
  components: { HeaderComponent, SidebarComponent },
  data() {
            return {
                sendCommand: ''
            }
        },
  methods: {
  	closeOpen () {
      if (this.sendCommand === ''){
          this.sendCommand = "open"
      } else {
          this.sendCommand = ""
      }
      
    	console.log("action")
    }
  }
}
</script>
<style lang="scss" scoped>
  .c-main, .c-sidebar{
    background-color:#fff;
  }
  .c-sidebar{
    border-right:solid 1px #ccc;
  }
</style>
