<template>
  <div class="card no-radius redBG">
    <div class="card-body">
      <div class="row">
        <div class="col-4 mb-3">
          <UserAvatarComponent/>
        </div>
        <div class="col-8 text-left font-weight-bold mb-3">
          Welcome,
          <br>
          {{ user.name }}
        </div>
        <div class="col-12">
          {{ user.email }}
          <br>
          <router-link to="/my-profile" class="text-white">My Profile</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import UserAvatarComponent from '@/components/misc/UserAvatarComponent'

export default {
  name: 'SidebarUserComponent',
  components: { UserAvatarComponent },
  computed: {
    ...mapState('auth', ['user'])
  }
}
</script>
<style lang="scss" scoped>
.text-white {
  color:#fff
}
.card {
  background-color: #3c4b64;
  border: none;
  width: 100%;
}
.redBG{
background-color:#B2241A;
}
</style>
