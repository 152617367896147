<template>
  <div v-if="sendCommand" class="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-show" id="sidebar" ref="sidebar">
    {{sendCommand}}
   <SidebarUserComponent />
    <ul class="c-sidebar-nav">
      <SidebarItemComponent
        v-for="(val, key) in navigation" :key="key"
        :data="val"
      />
    </ul>
  </div>
  <div v-else class="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show" id="sidebar" ref="sidebar">
    <SidebarUserComponent />
    <ul class="c-sidebar-nav">
      <SidebarItemComponent
        v-for="(val, key) in navigation" :key="key"
        :data="val"
      />
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import nav from '@/_nav'
import navMerchant from '@/_nav_merchant'
import navOperator from '@/_nav_operator'
import SidebarItemComponent from '@/components/sidebar/SidebarItemComponent'
import SidebarUserComponent from '@/components/sidebar/SidebarUserComponent'

const logo = require('@/assets/images/probanlogo-small.png')
export default {
  name: 'SidebarComponent',
  props: ['sendCommand'],
  components: { SidebarUserComponent, SidebarItemComponent },
  computed: {
    ...mapGetters('auth', ['isMerchant', 'isOperator']),
    navigation () {
      if (this.isMerchant) return navMerchant.items
      if (this.isOperator) return navOperator.items
      return nav.items
    }
  },
  data () {
    return {
      logo
    }
  }
}
</script>
<style lang="scss" scoped>
ul {
  align-items: flex-start;
  padding-left: 1rem;

}
.c-sidebar-nav{
  padding-left:0;
}
.c-sidebar ul li{
    width:100%;
  }
</style>
