<template>
  <li
    :class="[
            data.title ? 'c-sidebar-nav-title' : 'c-sidebar-nav-item',
            data.submenu && data.submenu.length ? 'c-sidebar-nav-dropdown sideBarBorder' : 'sideBarBorder'
           ]" >
    <router-link :to="data.url" class="c-sidebar-nav-link">
      <i :class="data.icon" class="mr-3"></i>{{ data.name }}
    </router-link>
    <SidebarChildItemComponent
      v-if="data.submenu && data.submenu.length"
      :navigation="data.submenu"
    />
  </li>
</template>
<script>
import SidebarChildItemComponent from '@/components/sidebar/SidebarChildItemComponent'
export default {
  name: 'SidebarItemComponent',
  components: { SidebarChildItemComponent },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
i {
  width: 15px;
}
.sideBarBorder{
  border-bottom: solid 1px #eee;
}
</style>
